import axios from 'axios';

let qs = require('qs');
const sprintf = require('sprintf-js').sprintf;

export default class AttributeOptionsClient {
  constructor(type) {
    this.type = type;
  }

  async categories({pathParameters, queryStrings} = {}) {
    let genericUrl = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/categories`;
    let productsUrl = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/institutions/%(institutionId)s/segments/%(segmentId)s/categories`;
    let url = this.type === "products" ? productsUrl : genericUrl;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async insuranceTypes({queryStrings} = {}) {
    const url = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/insurance-types`;
    return axios({
      url: url,
      method: "get",
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: "repeat"})
      },
    });
  }

  async category({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/categories/%(categoryId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async subCategories({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/categories/%(categoryId)s/sub-categories`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async segments({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_ATTRIBUTE_OPTIONS_V1_BASE_URL}/${this.type}/institutions/%(institutionId)s/segments`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }
}
